import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../../layout/Layout';
import ServiceIntroduction from '../../components/services/ServiceIntroduction';
import HomeConsultation from '../../components/home/HomeConsultation';
import FlexibleBlockStorageSlider from '../../components/pricing/FlexibleBlockStorageSlider';
import colors from '../../theme/colors';
import PriceContainer from '../../components/services/PriceContainer';
import RelatedGuidesContainer from '../../components/services/RelatedGuidesContainer';
import FunctionalityAndCasesContainer from '../../components/services/FunctionalityAndCasesContainer';

const infoWrapper = {
    maxWidth: '1440px',
    width: '100%',
    backgroundColor: colors.darkViolet,
    padding: '0px 136px 40px',
    minHeight: '649px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
};

const homeConsultationProps = {
    mainContainer: {
        padding: '102px 0px 56px',
    },
};

const serviceIntroductionData = {
    title: 'Flexible Block Storage',
    firstParagraph: 'Volúmenes de alta velocidad para tus aplicaciones más exigentes.',
    secondParagraph: 'Aumentá la capacidad de almacenamiento de tus máquinas virtuales de manera rápida, segura y sin interrupciones.',
    gatsbyImg: (<StaticImage src="../../images/flexible-block_storage_icon.svg" alt="Almacenamiento" />),
    isButton: true,
    externalLinkButton: (
        <a
            href="https://portal.nubi2go.com/index.php?rp=/store/flexible-block-storage"
            style={{ textDecoration: 'none' }}
            target="_blank"
            rel="noreferrer"
        >
            <Button
                variant="contained"
                aria-label="Crear ahora"
                style={{ width: '100%', height: '100%' }}
            >
                Crear ahora
            </Button>
        </a>),
};
const relatedGuidesData = {
    guides: [
        {text:'Cómo conectar en Linux', link: "https://nubi2go.com/docs/flexible_block_storage/#como-conectar-el-volumen-en-linux"},
        {text:'Cómo conectar en Windows', link: "https://nubi2go.com/docs/flexible_block_storage/#como-conectar-el-volumen-en-windows"}
    ],
    servicesData: [
        'Replicación de 3 a 1.',
        'Basado en ceph.',
        'Independiente de las vms.',
    ],
};
const priceContainerData = {
    title: 'Conocé tu precio',
    text: (
        <FlexibleBlockStorageSlider />
    ),
    customContainerProp: {
        minHeight: '446px',
    },
    customTextProp: {
        paddingBottom: '48px',
    },
};
const casesOfUseData = {
    title: 'Funcionalidades del servicio',
    cases: [
        {
            image: (<StaticImage src="../../images/hot_volumes.svg" alt="Volumenes" />),
            text: 'Conectar volúmenes a instancia en caliente',
        },
        {
            image: (<StaticImage src="../../images/snapshots.svg" alt="Snapshot" />),
            text: 'Realizar Snapshots',
        },
        {
            image: (<StaticImage src="../../images/instance_connection.svg" alt="Instancias" />),
            text: 'Conectarse a varias instancias',
        },
        {
            image: (<StaticImage src="../../images/volume_expansion.svg" alt="Expandir volumen" />),
            text: 'Expandir el tamaño de un volumen',
        },
    ],
    gridColumns: 12,
    maxRowGridSize: 3,
};

const FlexibleBlockStorage = () => (
    <Layout>
        <ServiceIntroduction serviceIntroductionData={serviceIntroductionData} />

        <Box
            style={{
                backgroundColor: colors.darkViolet,
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <Box sx={(theme) => ({ [theme.breakpoints.down('sm')]: { padding: '20px 0px' }, ...infoWrapper })}>
                <FunctionalityAndCasesContainer casesOfUseData={casesOfUseData} />
                <Box sx={(theme) => ({ [theme.breakpoints.down('sm')]: { padding: '0px 24px' }})}>
                <RelatedGuidesContainer relatedGuidesData={relatedGuidesData} />
                </Box>
            </Box>
        </Box>
        <PriceContainer priceContainerData={priceContainerData} />

        <HomeConsultation homeConsultationProps={homeConsultationProps} />
    </Layout>
);

export default FlexibleBlockStorage;
